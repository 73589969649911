import React from 'react';
import './Footer.css';
import ShareFooter from './ShareFooter';
import callUs from '../../static/images/call_us.png';
import creditCards from '../../static/images/credit_cards.png';

export default () => (
  <div>
    <footer className="footer">
      <div className="container taCenter">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ShareFooter />
          <div style={{ marginTop: -2, marginLeft: 8 }}>
            <a href="tel:01623 400 500">
              <img style={{ width: 94 }} src={callUs} alt="Call Us" />
            </a>
          </div>
          <div style={{
            marginTop: -10,
            marginLeft: 12,
          }}
          >
            <img style={{ width: 92 }} src={creditCards} alt="Call Us" />
          </div>
        </div>
        <span>
          © Copyright
          {' '}
          {new Date().getFullYear()}
          {' '}
          All rights reserved. Crafted by
          {' '}
          <a href="https://www.clockworkbee.co.uk">Clockwork Bee Web Design</a>
          .
        </span>
      </div>
    </footer>
  </div>
);
