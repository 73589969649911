import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Meta from './Meta';
import Nav from './Nav';
import Footer from './Footer';
// import GithubCorner from './GithubCorner';
import vosa from '../../static/images/image001.png';

import 'modern-normalize/modern-normalize.css';
import './globalStyles.css';

export default ({ children, meta, title }) => (
  <StaticQuery
    query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
    render={(data) => {
      const { siteTitle, socialMediaCard, googleTrackingId } = data.settingsYaml || {};
      const subNav = {
        posts: data.allPosts.hasOwnProperty('edges')
          ? data.allPosts.edges.map((post) => ({ ...post.node.fields, ...post.node.frontmatter }))
          : false,
      };

      return (
        <>
          <Helmet
            defaultTitle={siteTitle}
            titleTemplate={`%s | ${siteTitle}`}
          >
            {title}
            <link href="https://ucarecdn.com" rel="preconnect" crossOrigin />
            <link rel="dns-prefetch" href="https://ucarecdn.com" />
            {/* Add font link tags here */}
          </Helmet>

          <Meta
            googleTrackingId={googleTrackingId}
            absoluteImageUrl={
                socialMediaCard
                && socialMediaCard.image
                && socialMediaCard.image
              }
            {...meta}
            {...data.settingsYaml}
          />
          <div
            style={{
              position: 'fixed',
              right: '64px',
              top: '0px',
              zIndex: '1000',
            }}
          >
            <img alt="vosa" style={{ width: '60px', height: 'auto' }} src={vosa} />
          </div>
          {/* <GithubCorner url="https://github.com/thriveweb/yellowcake" /> */}

          <Nav subNav={subNav} />

          <>{children}</>

          <Footer />
        </>
      );
    }}
  />
);
