import React from 'react';

import {
  FacebookIcon,
  // TwitterIcon,
} from 'react-share';

const ShareFooter = () => (
  <div>
    <a href="https://www.facebook.com/mechanicianlimited">
      <FacebookIcon size={40} round />
    </a>
  </div>
)
export default ShareFooter;
